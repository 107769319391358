@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e5e8e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Hide scrollbar */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Add styles in your main CSS file for mobile navbar animation */
@layer components {
  .translate-x-full {
    transform: translateX(100%);
  }
  .translate-x-0 {
    transform: translateX(0%);
  }
}

/* Input Field Glow Effect */
.input-field {
  @apply w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400;
  transition: box-shadow 0.3s ease-in-out, transform 0.2s;
}

.input-field:focus {
  box-shadow: 0 0 10px rgba(128, 0, 255, 0.8);
  transform: scale(1.02);
}

/* Button Glow Effect */
.btn-glow {
  position: relative;
  transition: transform 0.2s, box-shadow 0.3s;
}

.btn-glow:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(64, 0, 255, 0.8);
}

.btn-glow:active {
  transform: scale(0.98);
  box-shadow: none;
}


/* popup css for whatsapp */

/* Popup animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animate-bounce {
  animation: bounce 1.5s infinite;
}

/* Popup styling */
.fixed {
  position: fixed;
}

.bottom-4 {
  bottom: 1rem;
}

.right-4 {
  right: 1rem;
}

.bg-white {
  background-color: white;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.border {
  border: 1px solid #e5e7eb;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.gap-4 {
  gap: 1rem;
}
